.headerBg {
    background: #343626;
}

.headerMain {
    padding: 0 4rem;
}

.heart_count_position {
    position: absolute;
    right: -2px;
    top: -3px;
    background: #990606;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    margin: 0 auto;
    padding-right: 2px;
    padding-bottom: 2px;
    color: #fff !important;
    font-weight: 500;
}

.basketCount span {
    position:relative ;
}

@media (max-width: 450px) {
    .headerMain {
        padding: 0 1rem;
    }

}

.headerNav {
    padding-left: 3rem;
    width: 100% !important;
    display: flex !important;
    justify-content: space-between !important;
}

.headerImages {
    display: flex;
    align-items: center;
}

.header {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    position: relative;
}


.active  {
    color: red !important;
    text-decoration: none !important;
}

.headerImages span {
    margin: 0 0.5rem !important;
    cursor: pointer;
}

.headerImages span svg {
    font-size: 28px;
    color: #fafafa;
}

.headerImages span svg:hover {
    fill: red !important;
}

.headerNav  a {
    font-family: Poppins !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 50px !important;
    line-height: 36px !important;
    color: #FFFFFF !important;
    transform: matrix(0.98, 0, -0.18, 1, 0, 0) !important;
    padding: 15px 20px !important;
}

.headerNav a:hover {
    cursor: pointer !important;
}

.btnAm {
    background: none !important;
    border: none !important;
    display: flex;
    align-items: center;
}

.dropAm {
    width: 60px;
    background: #343626;
    text-align: center !important;
    margin-left: -3px;
}

.dropAm button {
    background: none;
    border: none;
    font-size: 14px;
    color: #FFFFFF;
}

.dropAm button:hover {
    background: none !important;
    color: #EEB960 !important;
}

.dropAm a {
    font-weight: 600;
    font-style: normal !important;
    font-size: 13px !important;
    line-height: 24px !important;
    color: #FFFFFF !important;
    /* width: 10% !important; */
    margin: 0 auto !important;
    padding: 0 5px;
    text-align: center !important;
}

.dropAm a:hover {
    background: none !important;
    color: #EEB960 !important;
}

.dropUser {
    background: #C4C4C4 !important;
    padding: 8px 10px 8px 10px;
    border-radius: 50%;
}

.cont1 a {
    text-decoration: none !important;
}

.loginBtn {
    border: none;
    padding: 5px 16px;
    border-radius: 5px;
    max-height: 44px;
    display: flex;
    align-items: center;

}

.basketCount {
    position: relative;
}

.basketCount div p{
    position: absolute;
    right: -2px;
    top: -10px;
    background: red;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    margin: 0 auto;
    padding-right: 1px;
    padding-bottom: 2px;
    color: #fff !important;
    font-weight: 500;
    z-index: 10000;
}

.linkProduct {
    display: flex !important;
    flex-direction: column !important;
}

.linkProduct a {
    text-decoration: none !important;
    padding: 0 !important;
}

.cont1 {
    display: flex;
    align-items: center;
}

.lika {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 17px !important;
    line-height: 36px !important;
    color: #FFFFFF !important;
    padding: 0 2rem;
    transform: matrix(0.98, 0, -0.18, 1, 0, 0) !important;
    text-decoration: none !important;
    position: relative;
    transition: 0.5s;
    text-align: center !important;
}

.dropdown {
    margin: 0 2rem !important;
}

.lika a {
    padding: 5px 20px !important;
    color: #ffffff !important;
    padding: 0 !important;
    transition: 0.8s;
    text-align: center !important;
}

.lika:hover {
    transition: 0.8s;
    color: #EEB960 !important;
}

@media only screen and (max-width:1052px) {
    .lika {
        font-size: 16px !important;

    }
    .dropAm {
        width: 156px;
        background: #343626;
        text-align: center !important;
        margin-left: -57px;
    }
}


@media only screen and (max-width:1045px) {
    .lika {
        padding: 0 0.5rem;

    }
}

@media only screen and (max-width:1215px) {
    .lika {
        padding: 0 1rem;
    }
}


@media only screen and (max-width: 1300px) {
    .cont1 {
        margin: 1rem auto;
        display: flex !important;
        width: 29% !important;
        justify-content: space-between;
    }

    .headerMain {
        padding: 0;
    }
}

@media only screen and (max-width: 950px) {
    .cont1 {
        margin: 2rem auto;
        display: flex !important;
        width: 80% !important;
        justify-content: space-between;
    }
}

@media only screen and (max-width: 500px) {
    .cont1 {
        margin: 2rem auto;
        display: flex !important;
        width: 100% !important;
        max-width: 100% !important;
        justify-content: space-between;
    }

    .lika {
        font-style: normal !important;
        font-weight: 400 !important;
        font-size: 22px !important;
        line-height: 38px !important;
        color: #FFFFFF !important;
        padding: 0 1.5rem 0 0;
        margin: 0 auto;
        transform: matrix(0.98, 0, -0.18, 1, 0, 0) !important;
        text-decoration: none !important;
        position: relative;
        transition: 0.5s;
        text-align: center !important;
    }
}

