.wishBg {
    background-image: url("../../Utils/images/bgFooter.png");
    background-color: #cccccc;
    height: 350px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 2rem 0;
}

.wishHome {
    display: flex;
    align-items: center;
    justify-content: start;
    margin: 0.8rem 0;
}

.wishHome a {
    text-decoration: none;
    color: black;
}

.wishHome p {
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
}

.wishHome span {
    padding: 0 0.5rem;
    font-size: 18px;
}

.wishHome svg {
    font-size: 18px;
}

.contact h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 38px;
    line-height: 57px;
    color: #000000;
}

.contact h2 {
    font-style: normal;
    font-weight: 300;
    font-size: 28px;
    line-height: 33px;
    color: #000000;
    margin: 2rem 0;
}

.formik {
    display: flex;
    flex-direction: column;
    width: 80%;
}

.formik input {
    margin: 0.5rem 0;
    text-indent: 10px;
}

.formik  input {
    border: 1px solid #A09898;
    box-sizing: border-box;
    border-radius: 8px;
    height: 3.5rem;
}

.formik div input::placeholder {
    font-size: 25px !important;
}

.ll input {
    padding-bottom: 5rem !important;
}

.btn {
    background: #BD9085;
    border-radius: 8px;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #FFFFFF;
    border: none;
    padding: 10px 0;
    margin-top: 1rem;
}

.btn:hover {
    background: rgba(220, 176, 165, 0.6);
    border-radius: 8px;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #FFFFFF;
    border: none;
    padding: 10px 0;
    margin-top: 1rem;
    transition: 1s;
}

.formik div input fieldset legend span {
    color: red !important;
}

.bgImg {
    margin-top: 3rem;
    margin-left: 8rem;
    background-image: url("../../Utils/images/contactBg.png");
    height: 600px; /* You must set a specified height */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover;
    position: relative;
    right: 0;
    display: flex;
    justify-content: end;
    align-items: end;
    width: 85%;
}

.posDiv {
    position: relative;
    margin-top: 2rem;
    width: 100%;
}

.absalute {
    position: absolute;
    top: 0;
    margin-top: 9rem;
}

.absalute div {
    display: flex;
    align-items: center;
    background: linear-gradient(0deg, #F9F9F9, #F9F9F9), linear-gradient(120.62deg, rgba(255, 255, 255, 0.6) -1.37%, rgba(255, 255, 255, 0.2) 97.13%);
    box-shadow: 10px 16px 50px rgba(0, 48, 73, 0.05);
    border-radius: 16px;
    padding: 7px 15px 3px 0;
    margin: 1rem 0;
    width: 100%;
}

.one {
    margin-left: 4rem !important;
}

.two {
    margin-left: 2rem !important;
}

@media(max-width: 700px) {

    .absalute {
        width: 100%;
    }

    .absalute div h5 {
        width: 50% !important;
    }

    .absalute div img {
        width: 15% !important;
    }

    .one {
        margin: 0 !important;
    }

    .two {
        margin: 0 !important;
        /* width: 100%; */
    }

    .absalute {
        gap: 10px !important;
    }
    .absalute div {
        width: 50% !important;
    }
    .wishBg  {
        margin: 0 !important;
    }
}

@media only screen and (max-width: 1250px) {

    .posDiv {
        position: relative;
        margin-top: 8rem;
        width: 100%;
    }

    .absalute {
        position: absolute;
        top: 0;
        margin-top: 6rem;
    }

    .wishBg {
        background-image: url("../../Utils/images/bgFooter.png");
        background-color: #cccccc;
        height: 220px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin: 2rem 0;
    }
}

@media only screen and (max-width: 1000px) {



    .bgImg {
        margin-top: 3rem;
        margin-left: 8rem;
        background-image: url("../../Utils/images/contactBg.png");
        height: 200px; /* You must set a specified height */
        background-repeat: no-repeat; /* Do not repeat the image */
        background-size: cover;
        position: relative;
        right: 0;
        display: flex;
        justify-content: end;
        align-items: end;
        width: 85%;
    }

    .formik {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    /* .posDiv img {
        width: 100% !important;
    } */

    .absalute div {
        display: flex;
        align-items: center;
        background: linear-gradient(0deg, #F9F9F9, #F9F9F9), linear-gradient(120.62deg, rgba(255, 255, 255, 0.6) -1.37%, rgba(255, 255, 255, 0.2) 97.13%);
        box-shadow: 10px 16px 50px rgba(0, 48, 73, 0.05);
        border-radius: 16px;
        padding: 7px 15px 3px 0;
        margin: 0.3rem 0;
        width: 100%;
    }


    @media only screen and (max-width:355px) {
        
    .absalute div {
        display: flex;
        align-items: center;
        background: linear-gradient(0deg, #F9F9F9, #F9F9F9), linear-gradient(120.62deg, rgba(255, 255, 255, 0.6) -1.37%, rgba(255, 255, 255, 0.2) 97.13%);
        box-shadow: 10px 16px 50px rgba(0, 48, 73, 0.05);
        border-radius: 16px;
        padding: 0;
        margin: 0;
        width: 100%;
    }
    }

    .wishBg {
        background-image: url("../../Utils/images/bgFooter.png");
        background-color: #cccccc;
        height: 250px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin: 3rem 0;
    }

    .absalute {
        position: relative;
        margin: 0 auto;
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    @media only screen and (max-width:355px) {
        .absalute {
            margin: 0 0 0 -33px !important;
        }
    }

    .posDiv {
        display: flex !important;
        flex-direction: column;
    }

    .wishBg {
        background-image: url("../../Utils/images/bgFooter.png");
        background-color: #cccccc;
        height: 200px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin: 3rem 0;
    }
}

@media only screen and (max-width:1250px) {
    .myImg {
        width: 600px !important;
        height: 400px !important;
    }
}



@media only screen and (max-width:980px) {
    .myImg {
        width: 370px !important;
        height: 200px !important;
    }
}

@media only screen and (max-width:769px) {
    .absalute h5 {
       font-size: 13px !important;
    }

    .myImg {
        width: 520px !important;
        height: 350px !important;
    }

    .posDiv {
        position: relative;
        margin-top: 3rem;
        width: 100%;
    }
}

@media only screen and (max-width:600px) {
   .myImg {
        width: 139px !important;
        height: 200px !important;
        margin: 0 auto;
   }

   .absalute div {
       width: 100% !important;
   }
}



@media only screen and (max-width: 550px) {

    .contact {
        text-align: center;
    }

    .posDiv {
        position: relative;
        width: 80%;
        margin: 1rem auto;
    }

    .formik {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        width: 90%;
    }

    .formik {
        display: flex;
        flex-direction: column;
        width: 80%;
    }

    .bgImg {
        margin-top: 3rem;
        margin-left: 8rem;
        background-image: url("../../Utils/images/contactBg.png");
        height: 200px; /* You must set a specified height */
        background-repeat: no-repeat; /* Do not repeat the image */
        background-size: cover;
        position: relative;
        right: 0;
        display: flex;
        justify-content: end;
        align-items: end;
        width: 85%;
    }

    .wishBg {
        background-image: url("../../Utils/images/bgFooter.png");
        background-color: #cccccc;
        height: 250px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin: 5rem 0;
    }

    .absalute {
        position: relative;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .posDiv {
        display: flex !important;
        flex-direction: column;
    }

    .wishBg {
        background-image: url("../../Utils/images/bgFooter.png");;
        background-color: #cccccc;
        height: 100px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin: 3.5rem 0;
    }
}



@media only screen and (max-width:400px) {
    .posDiv .myImg {
        width: 200px !important;
        height: 300px !important;
    }
}



