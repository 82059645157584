.loginMain {
    width: 100%;
    display: flex;
    justify-content: center;
    background: rgba(252, 248, 248, 0.87);
    height: 100vh;
}

.loginOne {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: end !important;
    align-items: center !important;
}

.loginTwo {
    width: 50%;
    height: 100%;
}

.loginBg {
    background-image: url("../../Utils/images/loginBg.png");
    height: 100%;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    width: 100%;
    object-fit: cover;
}

.login1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    margin: auto;
}

.logintext {
    width: 70%;
}



.logintext h1 {
    font-family: Roboto Slab;
    font-style: normal;
    font-weight: normal;
    font-size: 60px;
    line-height: 70px;
    letter-spacing: -1.34px;
    color: #000000;
}

.logintext h3 {
    font-family: Playfair Display;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 44px;
    letter-spacing: -0.64px;
    color: #343626;
}

.errorAlert {
    padding: 20px 10px;
    /* background: red; */
    color: red;
    display: flex;
    align-items: center;
    width: 70%;
    border-radius: 10px;
    font-size: 20px !important;
}

.logintext span {
    font-family: Playfair Display;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 44px;
    letter-spacing: -0.64px;
    color: #EEB960;
}

.loginInp {
    display: flex;
    flex-direction: column;
    width: 70%;
}

.loginInp input {
    margin: 0.8rem 0;
    border: 1px solid #343626;
    box-sizing: border-box;
    border-radius: 6px;
    background: #fff !important;
    padding: 15px 0;
    text-indent: 10px;
}

.rememberMe {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.rememberMe div {
    display: flex;
    align-items: center;
}

.rememberMe div input {
    width: 15px;
    height: 15px;
    cursor: pointer;
}

.rememberMe div p {
    margin: 0 10px;
    font-family: Roboto Slab;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 34px;
    letter-spacing: -0.33px;
    color: #032D23;
}

.rememberMe div a {
    font-family: Roboto Slab;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 34px;
    letter-spacing: -0.33px;
    color: #032D23;
    text-decoration: none;
}

.login1 button {
    background: #28291F;
    border-radius: 6px;
    width: 70%;
    border: none;
    padding: 12px 0;
    font-family: Roboto Slab;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 34px;
    color: #FFFFFF;
    margin: 0.5rem 0;
}

.login1 button:hover {
    background: #57584C;
    border-radius: 6px;
    width: 70%;
    border: none;
    padding: 12px 0;
    font-family: Roboto Slab;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 34px;
    color: #FFFFFF;
    margin: 0.5rem 0;
}

.btnDivSignUp {
    width: 70%;
    display: flex;
    justify-content: start;
    align-items: start;
}

.loginSpan {
    display: flex;
    justify-content: start;
    align-items: center;
    border-radius: 5px;
    transition: 0.5s;
    text-align: center;
    margin-bottom: 1rem;
}
.loginSpan:hover {
    display: flex;
    justify-content: start;
    align-items: center;
    color: #fff !important;
    border-radius: 5px;
    /* background-color: #57584C; */
    transition: 0.5s;
    text-align: center;
    margin-bottom: 1rem;
}

.loginSpan p {
    font-family: Roboto Slab;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 34px;
    letter-spacing: -0.33px;
    color: #343626;
    margin: 0;
}

.loginSpan a {
    font-family: Roboto Slab;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 34px;
    letter-spacing: -0.33px;
    color: #343626;
    border: 1px solid;
    text-decoration: none;
    padding: 3px 10px;
    border-radius: 5px;
}
.loginSpan a:hover {
    color: #fff;
    text-decoration: none;
    background-color: #57584C;
    transition: .5s;
}

.inpIcon {
    position: relative;
    width: 100% !important;
}

.inpIcon input {
    width: 100%;
}

.inpIcon span {
    position: absolute;
    right: 20px;
    top: 22px;
    font-size: 21px;
    cursor: pointer;
}



@media (max-width: 905px) {
    .loginSpan {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        text-align: center;
        width: 50% !important;
    }

    .logintext h1 {
        font-size: 40px !important;
    }

    .logintext h3 {
        font-size: 30px !important;
    }

    .logintext h3 span {
        font-size: 30px !important;
    }

}

@media(max-width: 991px) {
    .loginTwo {
        display: none;
    }

    .logintext {
        width: 70%;
    }

    .login1 button {
        background: #28291F;
        border-radius: 6px;
        width: 70%;
        border: none;
        padding: 12px 0;
        font-family: Roboto Slab;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 34px;
        color: #FFFFFF;
        margin: 0.5rem 0;
    }

    .errorAlert {
        padding: 20px 10px;
        color: red;
        display: flex;
        align-items: center;
        width: 70%;
        border-radius: 10px;
        font-size: 20px !important;
    }

    .btnDivSignUp {
        width: 100%;
        display: flex;
        justify-content: start;
        align-items: start;
    }

    .loginSpan {
        display: flex;
        justify-content: start;
        align-items: center;
        margin: 0 auto;
        text-align: center;
        width: 50% !important;
    }

    .loginOne {
        width: 80%;
        height: 100%;
        display: flex;
        justify-content: end !important;
        align-items: center !important;
    }

    .loginInp {
        display: flex;
        flex-direction: column;
        width: 70%;
    }

    .loginSpan {
        display: flex;
        justify-content: start;
        align-items: center;
        width: 100%;
        text-align: center;
    }

    .btnOne {
        background: #28291F;
        border-radius: 6px;
        width: 100%;
        border: none;
        padding: 12px 0;
        font-family: Roboto Slab;
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        line-height: 34px;
        color: #FFFFFF;
        margin: 0.5rem 0;
        text-align: center;
        text-decoration: none;
    }

}

@media(max-width: 850px) {
    .loginMain {
        width: 100%;
        display: flex;
        justify-content: center;
        background: rgba(252, 248, 248, 0.87);
        height: auto;
    }

    .loginTwo {
        display: none;
    }
    .logintext {
        width: 70%;
        text-align: center;
    }
    .logintext h1 {
        font-size: 50px;
    }
}

@media(max-width: 770px) {
    .rememberMe {
        width: 80%;
        display: flex;
        flex-direction: column;
    }
}

@media(max-width: 500px) {
    .loginTwo {
        display: none;
    }
    .logintext {
        width: 70%;
        text-align: center;
    }
    .logintext h1 {
        font-size: 40px;
    }
    .rememberMe {
        width: 70%;
        display: flex;
        flex-direction: column;
    }
}


@media only screen and (max-width:426px) {
    .login1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 50px 0;
        margin: auto;
    }

    .loginSpan {
        width: 30% !important;
    }

    .loginSpan:hover {
       background-color: none !important;
    }
    
}