html,
body {
    padding: 0;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

.dropdown-menu {
    background: #343626 !important;
    box-shadow: 0px 12px 31px rgba(0, 0, 0, 0.4) !important;
    border-radius: 5px !important;
    text-decoration: none !important;
}

.dropdown-menu a:hover {
    background: none !important;
    color: #EEB960 !important;
}

.btn-primary:focus {
    box-shadow: none !important;
}

.slick-dots li button:before {
    font-size: 15px !important;
}

.lika Link{
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 19px !important;
    line-height: 36px !important;
    color: #FFFFFF !important;
    transform: matrix(0.98, 0, -0.18, 1, 0, 0) !important;
    padding: 5px 20px !important;
    text-decoration: none !important;
    position: relative;
}

.lika a {
    padding: 0 !important;
    color: #FFFFFF !important;

}

.lika:hover {
    font-size: 22px !important;
    color: #FFFFFF !important;
}

.active:hover {
    font-size: 22px !important;
    color: #FFFFFF !important;
}

.lika a:hover {
    font-size: 22px !important;
    color: #FFFFFF !important;
}

.navbar-light .navbar-nav .nav-link {
    color: #FFFFFF !important;
}


.active {
    width: 8px;
    height: 8px;
    background: none !important;
    border-radius: 50%;
    margin: 0 auto;
}


.dropdown-menu {
    min-width: 6rem !important;
}

.alert-heading {
    display: flex !important;
    align-items: center !important;
    margin: 0 auto;
    width: 50% !important;
    font-style: normal;
    font-weight: 500;
    font-size: 25px !important;
    line-height: 36px;
    color: #263238 !important;
}

.alert-heading span {
    margin-right: 1rem !important;
}

.alert-success {
    background: radial-gradient(100% 100% at 0% 0%, rgba(196, 196, 196, 0.5) 0%, rgba(104, 104, 104, 0.25) 100%) !important;
    backdrop-filter: blur(200px) !important;
}

.alertClose span {
    cursor: pointer !important;
}

.nav-item button {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    color: #000000 !important;
}
.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
    padding: 0 !important;
}

.css-1eqdgzv-MuiPaper-root-MuiSnackbarContent-root {
    background: #009F79 !important;
}

.css-kmxb0e-MuiButtonBase-root-MuiButton-root {
    color: red !important;
    font-weight: bold !important;
}

.css-kmxb0e-MuiButtonBase-root-MuiButton-root:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
    background-color: rgba(187, 17, 215, 0.04) !important;
    border: 1px solid red !important;
}

.slick-arrow {
    display: none !important;
}

.form-check-input .is-valid {
    width: 20px !important;
    height: 20px !important;
}

.form-check-input.is-valid:checked, .was-validated .form-check-input:valid:checked {
    background-color: #343626 !important;
}

.form-check-input.is-valid, .was-validated .form-check-input:valid {
    border-color: #343626 !important;
}

.form-check-input.is-valid~.form-check-label, .was-validated .form-check-input:valid~.form-check-label {
    font-style: normal !important;
    font-weight: 580 !important;
    font-size: 18px !important;
    line-height: 34px !important;
    letter-spacing: -0.33px !important;
    color: #032D23 !important;
    padding-top: 2px;
    padding-left: 0.5rem !important;
    cursor: pointer !important;
;
}

.form-check {
    display: flex !important;
    width: 100% !important;
    justify-content: space-between !important;
    align-items: center !important;
}

/*/s'wd;we,fweo,kdofwek,dpfweqkdewd[pew*/

.accordion-button:not(.collapsed) {
    color: black !important;
    background-color: #fff !important;
    box-shadow: none !important;
}

.accordion-button:focus {
    z-index: 3;
    border-color: none !important;
    outline: 0;
    box-shadow: none !important;
}

.MuiButton-root:hover {
    background: none !important;
}

.MuiButton-root:focus {
    background: none !important;
}

.accordion-item {
    border: none !important;
}

@media only screen and (max-width: 1200px) {
    .lika {
        font-style: normal !important;
        font-weight: 400 !important;
        font-size: 14px !important;
        line-height: 36px !important;
        color: #FFFFFF !important;
        transform: matrix(0.98, 0, -0.18, 1, 0, 0) !important;
        padding: 5px 20px !important;
        text-decoration: none !important;
        position: relative;
    }
}

@media only screen and (max-width: 1070px) {
    .lika {
        font-style: normal !important;
        font-weight: 400 !important;
        font-size: 12px !important;
        line-height: 36px !important;
        color: #FFFFFF !important;
        transform: matrix(0.98, 0, -0.18, 1, 0, 0) !important;
        padding: 5px 20px !important;
        text-decoration: none !important;
        position: relative;
    }
}

@media only screen and (max-width: 990px) {
    .lika {
        font-style: normal !important;
        font-weight: 400 !important;
        font-size: 18px !important;
        line-height: 36px !important;
        color: #FFFFFF !important;
        transform: matrix(0.98, 0, -0.18, 1, 0, 0) !important;
        padding: 5px 20px !important;
        text-decoration: none !important;
        position: relative;
    }
    .header_headerMain__i7hbN {
        padding: 0 !important;
    }

    .active {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        text-align: start;
        margin: 0 auto;
        position: absolute;
        left: 3rem;
    }

    .accordion {
            }
}

.swal2-image {
    width: 80px !important;
    height: 80px !important;
}

.swal2-styled .swal2-confirm {
    border: 0 !important;
    border-radius: 0.25em !important;
    background: initial !important;
    background-color: #1ba364 !important;
    color: #fff !important;
    font-size: 1em !important;
}

@media only screen and (max-width: 950px) {
    .header_headerMain__i7hbN {
        padding: 0 !important;
    }

    .active {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        text-align: start;
        margin: 0 auto;
        position: absolute;
        left: 3rem;
    }

    .accordion {
            }
}

.accordion-button {
    font-weight: bold;
}


@media only screen and (max-width: 550px) {
    .accordion {
           }
}

@media (max-width: 1435px) {
    .alert-heading {
        font-size: 20px !important;
    }
}

@media (max-width: 1280px) {
    .alert-heading {
        font-size: 16px !important;
    }
}

@media (max-width: 990px) {
    .alert-heading {
        text-align: center !important;
        font-size: 17px !important;
    }
}