.loader_section {
    width: 100%;
    z-index: 1000;
    top: 0;
    margin: 0;
    padding: 0;
 
  }
  
  .loader {
    width: 250px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: helvetica, arial, sans-serif;
    text-transform: uppercase;
    font-weight: 900;
    color: #555c4d;
    letter-spacing: 0.2em;
  }

  .loader img {
    width: 40px;
  }
  .loader::before,
  .loader::after {
    content: "";
    display: block;
    width: 15px;
    height: 15px;
    background: #555c4d;
    position: absolute;
    animation: load 0.7s infinite alternate ease-in-out;
  }
  .loader::before {
    top: 0;
  }
  .loader::after {
    bottom: 0;
  }
  @keyframes load {
    0% {
      left: 0;
      height: 30px;
      width: 15px;
    }
    50% {
      height: 8px;
      width: 40px;
    }
    100% {
      left: 235px;
      height: 30px;
      width: 15px;
    }
  }