.detailImgMain {
  width: 100%;
  height: 70vh;
  object-fit: cover;
  display: flex;
  flex-wrap: wrap;
}

.detailImgMain img {
  width: 100% !important;
  height: 100% !important;
  object-fit: contain;
  border-radius: 15px;
}

.err {
  color: red;
  padding-left: 1rem;
}

.detailImg img {
  width: 50%;
  cursor: pointer;
  opacity: 0.7;
  border-radius: 15px;
  object-fit: cover;
}

.detailImg img:nth-child(2) {
  margin-left: 0.8rem;
}

.detailImg img:hover {
  opacity: 1;
  transition: opacity 1s;
}

.detailImg {
  width: 40% !important;
  display: flex;
  margin-top: 2rem;
  height: 15vh;
}

@media (max-width: 450px) {
  .detailImg {
    width: 100% !important;
    display: flex;
    align-items: center;
    margin-top: 2rem;
    flex-wrap: wrap;
  }
}

.detailName {
  display: flex;
  flex-direction: column;
}

.detailName h2 {
  text-transform: uppercase;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  color: #4a5155;
  padding-bottom: 0 !important;
  margin-bottom: 0.5rem !important;
}

@media (max-width: 330px) {
  .detailName h2 {
    text-transform: uppercase;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    text-align: center;
    line-height: 40px;
    color: #4a5155;
    padding-bottom: 0 !important;
    margin-bottom: 0.5rem !important;
  }
}

.detailName span h3 {
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.05em;
  color: #4a5155;
  display: flex;
  align-items: center;
  padding-left: 0.5rem;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}

@media (max-width: 330px) {
  .detailName span h3 {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 25px;
    letter-spacing: 0.05em;
    color: #4a5155;
    display: flex;
    align-items: center;
    padding-left: 0.5rem;
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
  }
}

.h5Tit {
  font-weight: normal;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.05em;
  color: #4a5155;
  display: flex;
  align-items: center;
  padding-bottom: 0 !important;
  margin-bottom: 0.5rem !important;
  padding-top: 0.5rem !important;
}

@media (max-width: 330px) {
  .h5Tit {
    margin: 0 auto;
  }
}

.detailName span {
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.05em;
  color: #4a5155;
  display: flex;
  align-items: center;
  padding-top: 0.5rem;
}

@media (max-width: 330px) {
  .detailName span {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 33px;
    letter-spacing: 0.05em;
    color: #4a5155;
    display: flex;
    align-items: center;
    padding-top: 0.5rem;
  }
}

.detailName span p {
  color: #009f79;
  font-size: 22px;
  padding-left: 10px;
  font-weight: 700;
  margin: 0;
}

.detailName h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 46px;
  letter-spacing: 0.05em;
  color: #595a45;
  text-align: start;
}

.amount {
  display: flex;
  width: 53.5%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

@media only screen and (max-width: 375px) {
  .amount {
    display: block;
    width: 100%;
    /*justify-content: space-between;*/
    /*align-items: center;*/
    /*margin-bottom: 0.5rem;*/
  }
}

.amount p {
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 30px;
  text-align: center;
  color: #000000;
}

.PlusMinus {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spanClick {
  background: #ffffff;
  border: 1px solid #a09898;
  box-sizing: border-box;
  border-radius: 2px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  width: 33px;
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.count {
  margin: 0 0.5rem;
  padding: 3.5px 45px;
  background: #ffffff;
  border: 1px solid #a09898;
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  text-align: center;
  color: #000000;
}

.calendarText h3 {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
}

.data {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin: 1rem 0;
}

.dateInp {
  width: 75%;
  display: flex;
  align-items: center;
}

.dateInp input {
  background: #ffffff;
  border: 1px solid #a09898;
  box-sizing: border-box;
  border-radius: 4px;
  width: 280px;
  padding: 10px 0;
  cursor: pointer;
  text-indent: 5px;
}

.dateInp select {
  width: 280px;
  border-radius: 4px;
  text-indent: 5px;
  padding: 10px 0;
  border: 1px solid #a09898;
}

.texterea h3 {
  font-style: normal;
  font-size: 21px;
  line-height: 30px;
  letter-spacing: 0.05em;
  color: #686868;
}

@media (max-width: 330px) {
  .texterea h3 {
    font-size: 16px;
  }
}

.texterea input {
  width: 100%;
  padding: 10px 0 80px 10px;
  background: #ffffff;
  border: 1px solid #a09898;
  box-sizing: border-box;
  border-radius: 4px;
}

.texterea input::placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: #a09898;
}

.add {
  margin: 2rem 0;
  display: flex;
  justify-content: space-between;
  width: 40%;
  align-items: center;
}

.love {
  background: #bd9085;
  border-radius: 4px;
  border: none;
  padding: 8px 20px;
  font-size: 25px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.setLove {
  background: #bb2626;
  border-radius: 4px;
  border: 1px solid red;
  padding: 8px 20px;
  font-size: 25px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  display: flex;
  align-items: center;
  transition: 1s !important;
}

.love:hover {
  background: rgba(220, 176, 165, 0.6);
  border-radius: 4px;
  border: none;
  padding: 8px 20px;
  font-size: 25px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  display: flex;
  transition: 1s !important;
  align-items: center;
}

.clickk {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
  padding: 8px 30px;
  border: none;
  background: #bd9085;
  border-radius: 4px;
}

.clickk:hover {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
  padding: 8px 30px;
  border: none;
  background: rgba(220, 176, 165, 0.6);
  border-radius: 4px;
  transition: 1s !important;
}

.addMain {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.5rem;
}

.logo {
  display: flex;
  align-items: center;
}

.logo span {
  display: flex;
  align-items: center;
  margin-left: 0.5rem !important;
}

.logo span p {
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  line-height: 46px;
  color: #000000;
}

.diteilBg {
  background-image: url("../../Utils/images/bgFooter.png"); /* The image used */
  background-color: #cccccc; /* Used if the image is unavailable */
  height: 400px; /* You must set a specified height */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
}

.alert {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  width: 80% !important;
  margin: 0 auto;
}

.textClock {
  width: 100% !important;
  display: flex;
  justify-content: center;
  margin: 0.5rem 0;
  font-weight: bold !important;
  font-size: 20px;
  line-height: 42px;
  letter-spacing: 0.08em;
  color: #d51515;
}

@media (max-width: 1402px) {
}

@media (max-width: 1402px) {
  .add {
    margin: 2rem 0;
    display: flex;
    justify-content: space-between;
    width: 45%;
    align-items: center;
  }
}

@media (max-width: 1215px) {
  .add {
    margin: 2rem 0;
    display: flex;
    justify-content: space-between;
    width: 60%;
    align-items: center;
  }
}

@media (max-width: 985px) {
  .detailImg img {
    width: 40%;
    cursor: pointer;
    opacity: 0.7;
    border-radius: 15px;
    object-fit: cover;
    margin-left: 0.8rem;
    height: 19vh;
    margin-bottom: 10px;
  }

  .detailImg img:hover {
    opacity: 1;
    transition: opacity 1s;
  }

  .detailImg {
    width: 100% !important;
    display: flex;
    align-items: center;
    margin-top: 2rem;
    flex-wrap: wrap;
  }
}

@media (max-width: 970px) {
  .add {
    margin: 2rem 0;
    display: flex;
    justify-content: space-between;
    width: 40%;
    align-items: center;
  }

  .textClock {
    width: 100% !important;
    display: flex;
    justify-content: center;
    margin: 0.5rem 0;
    font-weight: bold !important;
    font-size: 30px;
    line-height: 42px;
    letter-spacing: 0.08em;
    color: #d51515;
    text-align: center;
  }

  .detailImgMain {
    width: 100%;
    height: 70vh;
    object-fit: cover;
  }

  .detailImg {
    width: 100% !important;
    max-width: 100% !important;
    display: flex;
    align-items: center;
    /*justify-content: space-between !important;*/
    margin-top: 2rem;
    margin-left: 0;
  }

  .diteilBg {
    background-image: url("../../Utils/images/bgFooter.png"); /* The image used */
    background-color: #cccccc; /* Used if the image is unavailable */
    height: 200px; /* You must set a specified height */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover;
  }
}

@media (max-width: 770px) {
  .add {
    margin: 2rem 0;
    display: flex;
    justify-content: space-between;
    width: 50%;
    align-items: center;
  }

  .diteilBg {
    background-image: url("../../Utils/images/bgFooter.png"); /* The image used */
    background-color: #cccccc; /* Used if the image is unavailable */
    height: 150px; /* You must set a specified height */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover;
  }
}

@media (max-width: 500px) {
  .detailImg {
    width: 100% !important;
    max-width: 100% !important;
    display: flex;
    align-items: center;
    /*justify-content: space-between !important;*/
    margin-top: 2rem;
    margin-left: 0;
  }

  .diteilBg {
    background-image: url("../../Utils/images/bgFooter.png"); /* The image used */
    background-color: #cccccc; /* Used if the image is unavailable */
    height: 100px; /* You must set a specified height */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover;
  }

  .addMain {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 1.5rem;
  }

  .add {
    margin: 2rem 0;
    display: flex;
    justify-content: space-between;
    width: 80%;
    align-items: center;
  }

  .textClock {
    width: 100% !important;
    display: flex;
    justify-content: center;
    margin: 2rem 0 0 0;
    font-weight: bold !important;
    font-size: 30px;
    line-height: 42px;
    letter-spacing: 0.08em;
    color: #d51515;
    text-align: center;
  }

  .logo {
    width: 80%;
    display: flex;
    justify-content: end;
    align-items: end;
  }

  .logo span {
    display: flex;
    align-items: center;
    margin-left: 0.5rem !important;
  }
}
