.mainOne {
    width: 85%;
    border: 20px solid #B6BB9B;
    box-sizing: border-box;
    border-radius: 35px;
    margin-right: 0;
    margin: 2rem 1.5rem;
}

.productHome {
    display: flex;
    justify-content: start;
    align-items: center;
    font-family: Poppins;
    font-weight: 200;
    font-size: 20px;
    line-height: 21px;
    color: #A09898;
    cursor: pointer;
    margin: 1rem 1.5rem;
}


.productSee {
    font-style: normal;
    font-weight: 450;
    font-size: 22px;
    line-height: 42px;
    color: #fff;
    display: block;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    margin: 2rem auto;
    text-align: center;
    background: #B6BB9B;
    width: 20%;
    border-radius: 8px;
    transition: 0.8s;
}

.productSee:hover {
    font-style: normal;
    font-weight: 450;
    background: #bbb69b;
    font-size: 22px;
    line-height: 42px;
    color: rgba(0,0,0);
    width: 20%;
    display: block;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    margin: 2rem auto;
    text-align: center;
    transition: 0.8s;
}

@media (max-width: 950px) {

    .productSee  {
        font-style: normal;
        font-weight: 450;
        font-size: 19px;
        line-height: 42px;
        color: #fff;
        display: block;
        justify-content: center;
        cursor: pointer;
        align-items: center;
        margin: 2rem auto;
        text-align: center;
        background: #B6BB9B;
        width: 80% !important;
        border-radius: 8px;
        transition: 0.8s;

    }
}

.productHome a {
    color: #A09898 !important;
}
.productHome p {
    margin: 0 !important;
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #A09898;
    cursor: pointer;
}

.productHome span {
    padding: 0 5px;
}

.mainTwo img {
    width: 100%;
    border-radius: 15px;
    height: 20rem;
    object-fit: cover;
    transition: all .2s ease-in-out;
    cursor: pointer;
}

.mainTwo img:hover {
    transform: scale(1.04);
    border-radius: 30px !important;
}

@media screen and (max-width: 900px) {
    .mainOne {
        width: 80%;
        border: 20px solid #B6BB9B;
        box-sizing: border-box;
        border-radius: 35px;
        margin: 0 auto;
        margin-top: 2rem;
    }
}