.productHome {
    display: flex;
    justify-content: start;
    align-items: center;
    margin: 1rem 0;
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 21px;
    color: #A09898;
    cursor: pointer;
    margin: 2rem 0;
}

.mainSelect {
    width: 100%;
    margin-left: 2rem;
}

.productHome a {
    color: #A09898;
}

.productHome p {
    margin: 0 !important;
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #A09898;
    cursor: pointer;
}

.productHome span {
    padding: 0 5px;
    font-weight: 400;
}

.sort {
    display: flex;
    justify-content: start;
    align-items: center;
    padding-top: 1rem;
}

.main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
}

.sort h2 {
    font-family: Inter;
    font-style: normal;
    font-weight: Bold;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
    margin-bottom: 1rem;
}

.sortFlexMain {
    display: flex;
    justify-content: space-between;
    align-items: center !important;
    width: 100%;
}

.sortFlexMain div p {
    margin: 0;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    color: #343626;
    padding-left: 0.2rem;
    padding-bottom: 0.5rem;
}

.sortFlexMain div input {
    width: 149px;
    height: 45px;
    background: #FFFFFF;
    border: 1px solid #A09898;
    box-sizing: border-box;
    border-radius: 6px;
    text-indent: 10px;
    margin-right: 1rem;
}

.mainDiv1 {
    width: 95% !important;
    flex-wrap: wrap;
    display: flex;
}

.widthInp {
    width: 100% !important;
}

.drop {
    width: 100% !important;
    display: flex;
    justify-content: space-between !important;
    align-items: center;
}

.select {
    width: 20rem !important;
    height: 45px !important;
    background: none !important;
    border: 1px solid #A09898;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #A09898;
    text-indent: 10px;
    cursor: pointer;
}

.select option {
    cursor: pointer !important;
}

option:hover {
    background-color: yellow !important;
}

.dropDown button:focus {
    box-shadow: none !important;
    width: 250px !important;
    height: 45px !important;
    background: none !important;
    border: 1px solid #A09898 !important;
    box-sizing: border-box !important;
}

.dropDown button:hover {
    box-shadow: none !important;
    width: 250px !important;
    height: 45px !important;
    background: none !important;
    border: 1px solid #A09898 !important;
    box-sizing: border-box !important;
}

.filterInp {
    margin-left: 3rem;
    width: 100px !important;
    text-indent: 10px;
}

.dropMenue {
    width: 100% !important;
    background: none !important;
}

.dropMenue form a {
    display: flex !important;
    align-items: center !important;
    justify-content: start !important;
    margin-bottom: 0.5rem !important;
}

.dropMenue  a input {
    width: 15px !important;
    height: 15px !important;
    margin-right: 2rem;
}

.dropDown button {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #A09898 !important;
}

.www {
    position: absolute;
    top: 40%;
    width: 100%;
    height: 58px;
    display: none;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
    color: #FFFFFF;
}

.www h2 {
    font-size: 13px;
}

.filterMain button {
    padding: 10px 20px;
    cursor: pointer !important;
    background: #FFFFFF;
    border: 1px solid #A09898;
    box-sizing: border-box;
    border-radius: 6px;
    margin-left: 1.5rem;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #A09898;
}

.filterMain button span {
    padding-left: 10px;
}



/*''''''''''''''''''''''''''''''''''''''''''''''''''''''''''*/
.FloralText {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 2rem 0;
}

.FloralText div {
    border: 2px solid #EEB960;
    width: 30%;
}

.FloralText h1 {
    font-family: Bhavuka;
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    line-height: 63px;
    text-align: center;
    letter-spacing: 0.2em;
    color: #332B25;
}

.floralTextP {
    text-align: center;
}

.floralTextP p {
    font-family: Alef;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 38px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #46362B;
}

.mainProduct {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    width: 100%;
    margin-bottom: 0.5rem;
}

.productDiv:hover {
    opacity : 0.9;
    transition:opacity 1s;
}

.productDiv {
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: end;
    border: 20px solid #B6BB9B;
    box-sizing: border-box;
    border-radius: 35px;
    position: relative;
    cursor: pointer;
}

.productDiv img {
    object-fit: cover;
    border-radius: 16px;
    transition: all .2s ease-in-out;
}
.productDiv img:hover {
    transform: scale(1.04);
    border-radius: 25px;
}


.productPrice {
    position: absolute;
    top: -14px;
    right: -15px;
    background: linear-gradient(106.94deg, #DCB0A5 -18.38%, #B6BB9B 103.01%);
    border: 6px solid #FFF8F8;
    border-radius: 49px;
    width: auto;
    height: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.setImg {
    background: linear-gradient(310.04deg, #a5766d -15.06%, #803a27 113.36%) !important;
    border: 2px solid red !important;
    padding: 10px !important;
    border-radius: 50% !important;
    width: 45px !important;
}


.loveInm:hover {
    background: linear-gradient(310.04deg, #a50f1f -15.06%, #800036 113.36%) !important;
    transition: 2s;
}

.activeBasket{
    background: linear-gradient(310.04deg, #a50f1f -15.06%, #800036 113.36%) !important;
}

.activeBasket:hover{
    background: linear-gradient(310.04deg, #a5501a -15.06%, #805b64 113.36%) !important;
}

.basketImg:hover{
    background: linear-gradient(310.04deg, #a50f1f -15.06%, #800036 113.36%) !important;
    transition: 2s;
}
.productPrice p {
    margin: 0;
    font-family: Fjalla One;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 30px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    padding: 0 25px;
}

.productText {
    display: flex !important;
    justify-content: start !important;
    flex-direction: column !important;
    align-items: start !important;
    margin-right: 50px !important;
    margin-top: 0.5rem;
    padding-left: 1rem;
    max-width: 100%;
}

.productText h2 {
    font-family: Abel;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.05em;
    color: #000000;
    display: flex !important;
    justify-content: start !important;
    flex-wrap: wrap !important;
    align-items: start !important;
    text-align: start;
}

.productText h3 {
    font-family: Abel;
    font-style: normal;
    font-weight: normal;
    font-size: 29px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #000000;
}

.productClick {
    display: flex !important;
    justify-content: space-between !important;
    align-items: start !important;
    width: 35%;
    margin-top: 0.5rem;
    padding-left: 1rem;
    margin-bottom: 1rem;
}

.productClick span {
    margin-right: 5px !important;
    cursor: pointer;
}

.productClick  img {
    cursor: pointer;
    background: #edcccc;
    border: 5px solid #FFFFFF;
    padding: 10px !important;
    border-radius: 50%;
}

.productClick  img:hover {
    background: #b86c6c !important;
    border: 5px solid #FFFFFF;
    padding: 10px !important;
    border-radius: 50%;
    transition: .4s !important; 
    cursor: pointer;
}

.productSee {
    font-style: normal;
    font-weight: 450;
    font-size: 22px;
    line-height: 42px;
    color: #fff;
    display: block;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    margin: 2rem auto;
    text-align: center;
    background: #B6BB9B;
    width: 20%;
    border-radius: 8px;
    transition: 0.8s;
}

.productSee:hover {
    font-style: normal;
    font-weight: 450;
    background: #bbb69b;
    font-size: 22px;
    line-height: 42px;
    color: rgba(0,0,0);
    width: 20%;
    display: block;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    margin: 2rem auto;
    text-align: center;
    transition: 0.8s;
}

@media (max-width: 1415px) {

    .productClick {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        width: 40% !important;
        margin-top: 0.5rem;
        padding: 0;
    }

}

@media (max-width: 1200px) {

    .productClick {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        width: 50% !important;
        margin-top: 0.5rem;
        padding: 0;
    }

    .mainProduct {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: 1rem;
        padding: 1rem;
    }

    .productDiv img {
        object-fit: cover;
        border-radius: 16px;
        width: 250px;
    }

    .www h2 {
        font-size: 10px;
    }

    .productText {
        display: flex !important;
        justify-content: center !important;
        flex-direction: column !important;
        align-items: center !important;
        margin: 0.5rem auto;
        text-align: center;
        width: 100%;
        max-width: 100%;
    }
    .select {
        width: 80px !important;
        height: 45px !important;
        background: none !important;
        border: 1px solid #A09898;
        box-sizing: border-box;
        border-radius: 5px;
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
        color: #A09898;
        text-indent: 10px;
        cursor: pointer;
    }

    .main {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 100% !important;
    }

}

@media (max-width: 1400px) {
    .select {
        width: 140px !important;
        height: 45px !important;
        background: none !important;
        border: 1px solid #A09898;
        box-sizing: border-box;
        border-radius: 5px;
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
        color: #A09898;
        text-indent: 10px;
        cursor: pointer;
    }
    .main {
        display: flex;
        align-items: center;
    }
}

@media (max-width: 950px) {

    .productSee  {
        font-style: normal;
        font-weight: 450;
        font-size: 19px;
        line-height: 42px;
        color: #fff;
        display: block;
        justify-content: center;
        cursor: pointer;
        align-items: center;
        margin: 2rem auto;
        text-align: center;
        background: #B6BB9B;
        width: 80% !important;
        border-radius: 8px;
        transition: 0.8s;

    }

    .www h2 {
        font-size: 12px;
    }
    .productPrice p {
        margin: 0;
        font-family: Fjalla One;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 30px;
        display: flex;
        align-items: center;
        color: #FFFFFF;
        padding: 0 25px;
    }
    .select {
        width: 180px !important;
        height: 45px !important;
        background: none !important;
        border: 1px solid #A09898;
        box-sizing: border-box;
        border-radius: 5px;
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
        color: #A09898;
        text-indent: 10px;
        cursor: pointer;
    }
    .mainSelect {
        width: 100%;
        margin-left: 0rem;
    }
}


@media (max-width: 920px) {

    .sortFlexMain {
        grid-gap: 10px !important;
    }
.main_sub_block{
    width: 100%;
}

    .sortFlexMain div{
    width: 100%;
    }
    .sortFlexMain div input {
        width: 100%;
        background: #FFFFFF;
        border: 1px solid #A09898;
        box-sizing: border-box;
        border-radius: 6px;
        text-indent: 10px;
        margin-right: 1rem;
    }
    .mainProduct {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-bottom: 1rem;
    }
    .productText {
        display: flex !important;
        justify-content: center !important;
        flex-direction: column !important;
        align-items: center !important;
        margin: 0.5rem 0 !important;
        padding: 0 !important;
        max-width: 100%;
    }
    .productClick {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        width: 100% !important;
        margin-top: 0.5rem;
        padding: 0;
    }
    .select {
        width: 170px !important;
        height: 45px !important;
        background: none !important;
        border: 1px solid #A09898;
        box-sizing: border-box;
        border-radius: 5px;
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
        color: #A09898;
        text-indent: 10px;
        cursor: pointer;
    }

    .main {
        display: flex;
        align-items: center;
    }
}

@media (max-width: 770px) {
    .main {
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .mainProduct {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 95%;
        margin-bottom: 1rem;
    }
    .sortFlexMain div input {
        width: 100%;
        background: #FFFFFF;
        border: 1px solid #A09898;
        box-sizing: border-box;
        border-radius: 6px;
        text-indent: 10px;
        margin-right: 0rem;
    }
    .sortH2 {
        margin-top: 1rem;
    }

}



@media (max-width: 500px) {
    .sortFlexMain {
        display: flex;
        flex-direction: column;
        align-items: center !important;
    }

    .mainProduct {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 93%;
        margin-bottom: 1rem;
    }

    .sortFlexMain {
        width: 100% !important;
        display: flex !important;
        justify-content: center !important;
        flex-direction: column !important;
        align-items: center !important;
    }

    .sortFlexMain div p {
        margin: 0.5rem 0;
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #686868;
    }

    .main {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .drop {
        width: 100% !important;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .sortFlexMain div input {
        width: 100%;
        background: #FFFFFF;
        border: 1px solid #A09898;
        box-sizing: border-box;
        border-radius: 6px;
        text-indent: 10px;
        margin-right: 0rem;
    }

    .select {
        width: 100% !important;
        height: 45px !important;
        background: none !important;
        border: 1px solid #A09898;
        box-sizing: border-box;
        border-radius: 5px;
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
        color: #A09898;
        text-indent: 10px;
        cursor: pointer;
    }

    .filterMain {
        width: 100%;
    }

    .filterMain button {
        width: 100%;
        cursor: pointer !important;
        background: #FFFFFF;
        border: 1px solid #A09898;
        box-sizing: border-box;
        border-radius: 6px;
        margin: 1rem 0;
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
        color: #A09898;
    }

    .filterMain button span {
        padding: 0 !important;
    }

    .mainSelect {
        width: 100%;
    }

    .mainSelect p {
        margin: 0.5rem 0 !important;
    }

    .sortH2 h2 {
        width: 100%;
        margin: 1rem 0;
        font-size: 20px;
        text-align: center;
    }

    .sort3 {
        margin: 1rem 0;
        font-size: 20px;
        text-align: center;
    }

    .sort {
        width: 80%;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .sortH2 {
        width: 78%;
    }

}

