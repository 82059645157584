.homeBg {
  width: 100% !important;
  /* background-image: url("../../Utils/images/homebg.png") !important;  */
  height: 550px !important; /* You must set a specified height */
  background-position: center !important; /* Center the image */
  background-repeat: no-repeat !important; /* Do not repeat the image */
  background-size: cover !important;
}

.homeBgText {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.img1 {
  display: flex !important;
}

.homeTextTwo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
  background: #c4c4c4;
  width: 100%;
  background: radial-gradient(
    152.32% 152.32% at 47.13% -4.64%,
    rgba(89, 90, 69, 0.5) 0%,
    rgba(89, 90, 69, 0.437087) 23.31%,
    rgba(89, 90, 69, 0.39) 76.41%
  );
  height: 350px;
}

.homeTextTwo h1 {
  font-family: Bhavuka;
  font-style: normal;
  font-weight: normal;
  font-size: 80px;
  line-height: 150px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #eeb960;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
}

.homeTextTwo h3 {
  font-family: Abel;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 51px;
  letter-spacing: 0.15em;
  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
}

.homeSlider1 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 5rem 0 3rem 0;
}

.homeSliderTExt1 {
  display: flex;
  flex-direction: column;
  margin-left: 5rem;
}

.homeSliderTExt1 span {
  width: 200px;
  height: 80px;
}

.homeSliderTExt1 h3 {
  font-family: Abhaya Libre;
  font-style: normal;
  font-weight: normal;
  font-size: 33px;
  line-height: 38px;
  color: #2f3640;
  transform: matrix(0.98, 0, -0.18, 1, 0, 0);
}

.homeSlider1Flex {
  display: flex;
  align-items: center;
  justify-content: center;
}


/* @media only screen and (max-width:376px) {
  .homeSlider1  img {
    width: 100px !important;
    height: 150px !important;
  }
} */

.homeSlider1Direction {
  display: flex;
  flex-direction: column;
  margin-left: 3rem;
  margin-top: 20px;
}

.homeSliderDiv2 {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between !important;
}

.homeSliderDiv2Text {
  display: flex;
  align-items: center;
  margin-left: 2rem;
}

.homeSliderText2Direction {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.homeSliderText2Direction h3 {
  font-family: Besley;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 45px;
  color: #313131;
  transform: matrix(0.98, 0, -0.18, 1, 0, 0);
}

.homeAbout {
  width: 70%;
  margin: 2.5rem auto;
  text-align: center;
  position: relative;
}

.homeAbout h1 {
  font-family: Bhavuka;
  font-style: normal;
  font-weight: normal;
  font-size: 34px;
  line-height: 63px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #46362b;
  border-bottom: 2px solid #eeb960;
  width: 70%;
  margin: 0 auto;
  margin-bottom: 1.5rem;
}

.homeAbout p {
  font-family: Alef;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 38px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #46362b;
}

.homeAbout a {
  font-style: normal;
  font-weight: 450;
  font-size: 18px;
  font-weight: 600;
  line-height: 42px;
  color: #fff;
  display: block;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  margin: 2rem auto;
  text-align: center;
  background: #B6BB9B;
  width: 20%;
  border-radius: 8px;
  border: 1px solid #B6BB9B;
  text-decoration: none;
  transition: 0.8s;
}

.homeAbout a:hover {
  color: white !important;
  background: #362f2d !important;
  transition: 0.8s !important;
}



@media only screen and (max-width: 1200px) {
  .homeTextTwo h1 {

    font-size: 55px;

  }

  .homeAbout a {

    font-size: 16px;

  }

}

.homeAbout a:hover {
  text-decoration: none;
  font-style: normal;
  font-weight: 600;
  background: #bbb69b;
  font-size: 18px;
  line-height: 42px;
  color: rgba(0, 0, 0, 0.7);
  width: 20%;
  display: block;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  margin: 2rem auto;
  text-align: center;
  transition: 0.8s;
}

.homeAboutImg {
  position: absolute;
  top: -450px;
  right: -80px;
  z-index: -10000000000;
}

.homeAboutImg  img {
  width: 500px;
  height: 800px !important;
}

.homeTouch {
  background: #343626;
}

.homeMap {
  width: 100%;
  margin: 3rem 0;
}

@media only screen and (max-width: 920px) {
  .homeTextTwo h1 {
    font-family: Bhavuka;
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    line-height: 60px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #eeb960;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  }

  .img1 {
    display: none;
  }

  .homeSliderDiv2Text {
    display: none !important;
  }

  .homeSlider1 {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 2rem 0 3rem 0;
  }

  .homeSliderDiv2 {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
  }

  .homeAboutImg {
    display: none;
  }

  .homeSliderTExt1 {
    display: flex;
    flex-direction: column;
    margin: 1rem 0;
    text-align: center;
  }
}

@media only screen and (max-width: 1390px) {
  .homeSliderDiv2 {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  .homeSliderDiv2Text {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100% !important;
  }

  .homeSliderTExt1 {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 2rem;
  }

  .homeAbout h1 {
    font-family: Bhavuka;
    font-style: normal;
    font-weight: normal;
    font-size: 34px;
    line-height: 63px;
    text-align: center;
    letter-spacing: 0.1em;
    color: #46362b;
    border-bottom: 2px solid #eeb960;
    width: 70%;
    margin: 0 auto;
    margin-bottom: 1.5rem;
  }

  .img1 {
    margin: 0 auto;
  }
  .www h2{
    font-size: 22px;
  }
}

@media (max-width: 770px) {
  .homeTextTwo h1 {
    font-family: Bhavuka;
    font-style: normal;
    font-weight: normal;
    font-size: 30px !important;
    line-height: 40px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #eeb960;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  }
  .www h2{
    font-size: 22px;
  }
}

@media only screen and (max-width: 800px) {
  .homeTextTwo h1 {
    font-family: Bhavuka;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 35px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #eeb960;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  }

  .www h2{
    font-size: 22px;
  }

  .homeSlider1Direction {
    display: none;
    flex-direction: column;
    margin-left: 0;
    margin-top: 20px;
  }

  .homeAbout h1 {
    font-family: Bhavuka;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 63px;
    text-align: center;
    letter-spacing: 0.1em;
    color: #46362b;
    border-bottom: 2px solid #eeb960;
    width: 70%;
    margin: 0 auto;
    margin-bottom: 1.5rem;
  }

  .img1 {
    display: none;
  }

  .homeSliderDiv2Text {
    display: none !important;
  }

  .homeSlider1 {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 2rem 0 3rem 0;
  }

  .img1 {
    display: none !important;
  }

  .homeTextTwo h3 {
    font-family: Abel;
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    line-height: 51px;
    letter-spacing: 0.15em;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
    text-align: center;
  }

  .homeSliderTExt1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem 0;
    text-align: center;
  }

  .homeSlider1Flex {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    margin: 0 auto;
    width: 80%;
  }

  .homeSliderDiv2 {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
  }

  .homeSlider1Flex img {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .homeAboutImg {
    display: none;
  }

  .homeSliderDiv2Text {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .homeSliderText2Direction {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 2rem;
  }

  .homeAbout a {
    font-style: normal;
    font-weight: 450;
    font-size: 22px;
    line-height: 42px;
    color: #fff;
    display: block;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    margin: 2rem auto;
    text-align: center;
    background: #B6BB9B;
    width: 80%;
    border-radius: 8px;
    border: 2px solid black;
    text-decoration: none;
    transition: 0.8s;
  }
}


@media (max-width: 990px) {
  .homeAbout a {
    font-style: normal;
    font-weight: 450;
    font-size: 15px;
    line-height: 42px;
    color: #fff;
    display: block;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    margin: 2rem auto;
    text-align: center;
    background: #B6BB9B;
    width: 80%;
    border-radius: 8px;
    border: 1px solid #B6BB9B;
    text-decoration: none;
    transition: 0.8s;

  }

  .homeAbout a:hover {
    font-style: normal;
    font-weight: 450;
    font-size: 15px;
    line-height: 42px;
    color: #fff;
    display: block;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    margin: 2rem auto;
    text-align: center;
    background: #90947a;
    width: 80% !important;
    border-radius: 8px;
    border: 1px solid #B6BB9B;
    text-decoration: none;
    transition: 0.8s;

  }

  .homeAbout h1 {
    font-family: Bhavuka;
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 33px;
    text-align: center;
    letter-spacing: 0.1em;
    color: #46362b;
    border-bottom: 2px solid #eeb960;
    width: 70%;
    margin: 0 auto;
    margin-bottom: 1.5rem;
  }

  .www h2{
    font-size: 22px;
  }
}