.home {
    margin: 2rem 0;
}

.divPrice {
    display: flex;
    align-items: center;
    padding: 0.5rem 0;
}

.divPrice h5 {
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 30px;
    color: #686868;
    width: 100%;
    padding: 10px 5px 10px 0;
}

.main {
    display: flex;
    justify-content: space-between !important;
    align-items: center;
    width: 100% !important;
}

.divOne {
    width: 90%;
    padding: 1rem 0;
}

.divTwo {
    width: 100%;
}

.home {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 8.8%;
}

.home span {
    font-size: 18px;
}

.nameInp {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 1rem 0;
}

.divOneOne {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.divOneOne div {
    display: flex;
    flex-direction: column;
}

.divOneOne div input {
    width: 98%;
}

.nameInp div h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    color: #686868;
    
}

.nameInp div input {
    padding: 10px 80px 10px 5px;
    background: #FFFFFF;
    border: 1px solid #A09898;
    box-sizing: border-box;
    border-radius: 4px;
    text-indent: 10px;
}

.mainInp {
    width: 100% !important;
}

.mainInp div {
    width: 100% !important;
}

.mainInp div input {
    width: 100% !important;
    padding: 12px 0;
    background: #FFFFFF;
    border: 1px solid #A09898;
    box-sizing: border-box;
    border-radius: 4px;
    text-indent: 10px;
}

.mainInp div h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    color: #686868;
    margin: 0.5rem 0;
}

.mainInp div select {
    width: 100% !important;
    padding: 12px 0;
    background: #FFFFFF;
    border: 1px solid #A09898;
    box-sizing: border-box;
    border-radius: 4px;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 24px;
    color: #686868;
    text-indent: 10px;
    cursor: pointer;
}

.paymentMain {
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 35px 25px;
}

.paymentProduct {
    width: 100%;
}

.paymentProduct h5  {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #686868;
    width: 100%;
    padding: 10px 0;
}

.paymentProduct div {
    border: 1px solid #C4C4C4;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    width: 100% !important;
    margin: 0 auto;
}

.prodName h5 {
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 30px;
    color: #686868;
    padding: 0 !important;
    margin-bottom: 20px;
}

.carts p {
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 30px;
    color: #686868;
}

.prodName {
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    font-style: normal;
    line-height: 24px;
    color: #000000;
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 24px;
    color: #000000;
    height: 200px;
    flex-direction: column;
    overflow-y: scroll;

}

.border {
    border: 1px solid #C4C4C4;
    width: 100% !important;
    margin: 0 auto;
    display: block !important;
}

.imagesCart {
    width: 50%;
    /* display: flex; */
    align-items: center;
    /* flex-direction: row !important; */
}
.paymentsBox{
    display: flex;
    text-align: left;
    color: #686868;
    font-size: 23px;
    flex-direction: row-reverse !important;
    justify-content: space-between;
}
.paymentsBox h4{
    font-size: 23px !important;
}


.visas{
    display: flex;
    
}


/* ==============================================carts style new */

.carts_items_block{
    width: 100%;
}
.title_carts{
    width: 100%;
    margin-top: 15px;
}

.title_carts p {
    font-size: 22px;
}


.visa{
    width: 100%;
    display: flex;
    align-items: center;
}
.text p{
    margin-left: 15px;
    margin-top: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    line-height: 24px;
    color: #000000;

}

.img_block button svg{
    width: 60px;
    height: 60px;
}

.img_block button{
    background: unset;
    border: none;
}
.img_block button svg:nth-child(2) {
    margin-left: 20px;
 
}


.idram{
    display: flex;
    align-items: center;
    grid-gap: 50px;
}

.idram button img{
    width: 100px;
    height: auto;
    margin: 0 0 0 -8px;
}
.idram button{
    background: unset;
    border: none;
}

.idram p{
    margin-left: 15px;
    margin-top: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    line-height: 24px;
    color: #000000;
}


/* ============================================================== */
.onlintBox{
    display: flex;
    text-align: left;
    color: #686868;
    font-size: 18px;
    flex-direction: row-reverse;
}

.imagesCart span {
    margin: 0 10px !important;
    cursor: pointer;
    padding: 0 !important;
    border: 1px solid #C4C4C4 !important;
    margin-top: 20px !important;
    height: 3.5rem !important;
    display: flex !important;
}

.imagesCart span image {
    height: 1rem !important;
    border: none !important;
}

.carts {
    display: flex;
    /* align-items: center; */
    /* justify-content: space-between; */
    flex-direction: column;
    text-align: left;
}

.prodTotal {
    width: 70%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 0px;
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 30px;
    color: #ec6161;
}

.prodTotal h5 {
    font-size: 25px;
    margin: 0 -20px;
}

.btn {
    display: flex;
    justify-content: end;
    align-items: end;
    margin: 1rem 0;
}

.btn button {
    background: #BD9085;
    border: 2px solid #DCB0A5;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 14px;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #FFFFFF;
    padding: 5px 20px;
}

.btn button:hover {
    background: rgba(220, 176, 165, 0.6);
    border: 2px solid #DCB0A5;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 14px;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #FFFFFF;
    padding: 5px 20px;
    transition: 0.5s;
}

.prodTotal p {
    margin: 0;
    font-size: 20px;
}

.btnAdd {
    width: 100%;
    display: flex;
    justify-content: end;
    margin: 2rem 0;
}

.btnAdd button {
    background: #DCB0A5;
    border: 2px solid #DCB0A5;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 14px;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #FFFFFF;
    padding: 5px 20px;
}

.errorEmail {
    color: red !important;
    font-size: 18px;
    padding: 10px 0;
}

@media only screen and (max-width: 1440px) {
    .divOne {
        width: 90%;
        max-width: 90%;
        padding: 1rem 0;
    }
    .nameInp div input {
        padding: 10px 50px 10px 5px;
        background: #FFFFFF;
        border: 1px solid #A09898;
        box-sizing: border-box;
        border-radius: 4px;
        text-indent: 10px;
    }
}

@media only screen and (max-width: 1201px) {
    .divOne {
        width: 90%;
        max-width: 90%;
        padding: 1rem 0;
    }
    .nameInp div input {
        padding: 10px 5px 10px 5px;
        background: #FFFFFF;
        border: 1px solid #A09898;
        box-sizing: border-box;
        border-radius: 4px;
        text-indent: 10px;
    }
    .divOneOne {
        display: block;
    }
}

@media only screen and (max-width: 990px) {
    .divOne {
        width: 100% !important;
        margin: 1rem 0;
    }

    .nameInp div {
        width: 100% !important;
        background: #FFFFFF;
        box-sizing: border-box;
        border-radius: 4px;
        text-indent: 10px;
    }

    .nameInp div input {
        width: 100%;
        background: #FFFFFF;
        border: 1px solid #A09898;
        box-sizing: border-box;
        border-radius: 4px;
        text-indent: 10px;
    }
    .divOne {
        width: 100% !important;
        padding: 1rem 0;
        margin: 0 auto;
    }
    .mediaBil {
        padding-left: 1.5rem;
    }

    .home {
        padding-left: 1.5rem;
    }
}

@media only screen and (max-width: 600px) {
    .nameInp {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin: 1rem 0;
    }

    .nameInp div {
        width: 100% !important;
        background: #FFFFFF;
        box-sizing: border-box;
        border-radius: 4px;
        text-indent: 10px;
    }

    .nameInp div input {
        width: 100%;
        background: #FFFFFF;
        border: 1px solid #A09898;
        box-sizing: border-box;
        border-radius: 4px;
        text-indent: 10px;
    }

    .mainInp h4 {
        padding-left: 0.8rem;
    }

    .mediaBil {
        padding-left: 1.5rem;
    }

    .home {
        padding-left: 1.5rem;
    }

}

@media only screen and (max-width: 400px) {
    .nameInp div input {
        padding: 10px 150px 10px 5px;
        background: #FFFFFF;
        border: 1px solid #A09898;
        box-sizing: border-box;
        border-radius: 4px;
        text-indent: 10px;
    }
    .visa{
        width: 100%;
        display: block;
        align-items: center;
    }

    .idram{
        display: block;
        align-items: center;
        grid-gap: 50px;
    }
}

@media only screen and (max-width: 280px) {
    .nameInp div input {
        padding: 10px 60px 10px 5px;
        background: #FFFFFF;
        border: 1px solid #A09898;
        box-sizing: border-box;
        border-radius: 4px;
        text-indent: 10px;
    }
}

.payBtns{
    background: none;
    border: none;
    outline: none;
}