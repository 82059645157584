.FloralText {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 2rem 0;
}

.FloralText div {
    border: 2px solid #EEB960;
    width: 30%;
}

.FloralText h1 {
    font-family: Bhavuka;
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    line-height: 63px;
    text-align: center;
    letter-spacing: 0.2em;
    color: #332B25;
}

.floralTextP {
    text-align: center;
}

.floralTextP p {
    font-family: Alef;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 38px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #46362B;
}

.mainProduct {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 3rem;
}

.productDiv:hover {
    opacity : 0.5;
    transition:opacity 1s;
}

.productDiv {
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: end;
    border: 20px solid #B6BB9B;
    box-sizing: border-box;
    border-radius: 35px;
    position: relative;
    cursor: pointer;
}

.productDiv img {
    object-fit: cover;
}

.productPrice {
    position: absolute;
    top: -14px;
    right: -15px;
    background: linear-gradient(106.94deg, #DCB0A5 -18.38%, #B6BB9B 103.01%);
    border: 6px solid #FFF8F8;
    border-radius: 49px;
    width: auto;
    height: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.productPrice p {
    margin: 0;
    font-family: Fjalla One;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 30px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    padding: 0 10px;
}

.productText {
    display: flex;
    justify-content: start;
    flex-direction: column;
    align-items: start;
    margin-right: 50px;
    margin-top: 0.5rem;
    max-width: 50%;
}

.productText h2 {
    font-family: Abel;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 46px;
    letter-spacing: 0.05em;
    color: #000000;
    display: flex !important;
    justify-content: start !important;
    flex-wrap: wrap !important;
    align-items: start !important;
    text-align: start;
}

.productText h3 {
    font-family: Abel;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #000000;
}

.productClick {
    display: flex !important;
    width: 55%;
    margin-top: 0.5rem;
}

.productClick span {
    margin-right: 6px !important;
    cursor: pointer;
}

.productClickSpan {
    width: 38%;
    display: flex !important;
    justify-content: space-between !important;
}

.productClick span img {
    background: linear-gradient(310.04deg, #DCB0A5 -15.06%, #DCB0A5 113.36%);
    border: 5px solid #FFFFFF;
    padding: 10px !important;
    border-radius: 50%;
    width: 45px!important;
}

.productClick span img:hover {
    background: linear-gradient(310.04deg, #a5766d -15.06%, #803a27 113.36%);
    border: 5px solid #FFFFFF;
    padding: 10px !important;
    border-radius: 50%;
    width: 45px!important;
}

.productSee {
    font-style: normal;
    font-weight: 450;
    font-size: 22px;
    line-height: 42px;
    color: #fff;
    display: block;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    margin: 2rem auto;
    text-align: center;
    background: #B6BB9B;
    width: 15%;
    border-radius: 8px;
    border: 1px solid #B6BB9B;
    text-decoration: none;
    transition: 0.8s;
}

.productSee:hover {
    text-decoration: none;
    font-style: normal;
    font-weight: 450;
    background: #362f2d;
    font-size: 22px;
    line-height: 42px;
    color: white;
    width: 15%;
    display: block;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    margin: 2rem auto;
    text-align: center;
    transition: 0.8s;
}

.setImg {
    background: linear-gradient(310.04deg, #a5766d -15.06%, #803a27 113.36%) !important;
    border: 2px solid red !important;
    padding: 10px !important;
    border-radius: 50% !important;
    width: 45px!important;
}


@media only screen and (max-width:1200px) {
    .productSee {
        font-style: normal;
        font-weight: 450;
        font-size: 16px;
        line-height: 42px;
        color: #fff;
        display: block;
        justify-content: center;
        cursor: pointer;
        align-items: center;
        margin: 2rem auto;
        text-align: center;
        background: #B6BB9B;
        width: 15%;
        border-radius: 8px;
        border: 1px solid #B6BB9B;
        text-decoration: none;
        transition: 0.8s;
    }

}

@media only screen and (max-width:1000px) {
    .productSee {
        font-style: normal;
        font-weight: 450;
        font-size: 15px;
        line-height: 42px;
        color: #fff;
        display: block;
        justify-content: center;
        cursor: pointer;
        align-items: center;
        margin: 2rem auto;
        text-align: center;
        background: #B6BB9B;
        width: 100%;
        border-radius: 8px;
        border: 1px solid #B6BB9B;
        text-decoration: none;
        transition: 0.8s;
    }

}

@media screen and (max-width: 912px) {
    .mainProduct {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: 3rem;
    }
    .productDiv {
        flex-direction: column;
        border: 20px solid #B6BB9B;
        box-sizing: border-box;
        border-radius: 35px;
        position: relative;
        cursor: pointer;
    }

    .productDiv img {
        object-fit: cover;
        width: 100%;
    }

    .productText {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-right: 0;
        text-align: center;
        margin-top: 0.5rem;
        max-width: 100%;
    }

    .productClick {
        display: flex !important;
        width: 100%;
        margin-top: 0.5rem;
        justify-content: center;
    }

    .productClickSpan {
        width: 80%;
        display: flex !important;
        justify-content: center !important;
    }
}

@media (max-width:1000px){
    .FloralText h1{
        font-size: 25px !important;
    }

}



@media (max-width:400px){
    .FloralText h1{
        font-size: 19px !important;
    }

}