.wishHome {
    display: flex;
    align-items: center;
    justify-content: start;
    margin: 0.8rem 0;
}

.wishHome p {
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
}

.wishHome span {
    padding: 0 0.5rem;
    font-size: 18px;
}

.wishHome svg {
    font-size: 18px;
}

.wishMyList {
    margin: 2rem 0;
}

.wishMyList h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 57px;
    color: #000000;
}


@media only screen and (max-width:800px) {
    
.wishMyList h1 {
    font-size: 30px;

}
}
.wishMyList h2 {
    font-style: normal;
    font-weight: 350;
    font-size: 25px;
    line-height: 36px;
    color: #000000;
}

.table {
    width: 100% !important;
    margin: 2rem 0;
}

.table table {
    border-collapse: collapse;
    width: 100% !important;
}

.tr {
    border-bottom: 1px solid #C4C4C4;
    text-align: center !important;
}

.tr td {
    width: 10% !important;
}

.tr th {
    padding: 0.8rem 0;
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.02em;
    color: #000000;
}

.tdR {
    border-right: 1px solid #C4C4C4;
}

.thImg {
    width: 80px !important;
    height: 80px !important;
    object-fit: cover;
    border-radius: 8px;
}

.PlusMinus {
    display: flex;
    justify-content: center;
    align-items: center;
}

.spanClick {
    background: #FFFFFF;
    border: 1px solid #A09898;
    box-sizing: border-box;
    border-radius: 2px;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    width: 33px;
    height: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.count {
    margin: 0 0.8rem;
    padding: 3.5px 55px;
    background: #FFFFFF;
    border: 1px solid #A09898;
    box-sizing: border-box;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    text-align: center;
    color: #000000;
}

.icons {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.iconsFlex {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tr td {
    padding: 1rem 0;
}

.tdR h5 {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    line-height: 24px;
    color: #000000;
}

.tdR h6 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
}

.iconsFlexImg {
    cursor: pointer !important;
}

.wishBg {
    background-image: url("../../Utils/images/bgFooter.png");
    background-color: #cccccc;
    min-height: 22rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 1rem 0;
}

@media only screen and (max-width: 1235px) {
    .wishBg {
        min-height: 16rem !important;
        margin: 1rem 0;
    }
}

@media only screen and (max-width: 878px) {
    .wishBg {
        min-height: 12rem !important;
        margin: 1rem 0;
    }
}

@media only screen and (max-width: 668px) {
    .wishBg {
        min-height: 9rem !important;
        margin: 1rem 0;
    }
}

@media only screen and (max-width: 510px) {
    .wishBg {
        min-height: 7rem !important;
        margin: 1rem 0;
    }
}

@media only screen and (max-width: 392px) {
    .wishBg {
        min-height: 6rem !important;
        margin: 1rem 0;
    }
}

@media only screen and (max-width: 350px) {
    .wishBg {
        min-height: 5rem !important;
        margin: 1rem 0;
    }
}


@media only screen and (max-width: 800px) {
    .table {
        border-collapse: collapse !important;
        width: 100% !important;
        overflow-x: auto !important;
    }

    .tr th {
        padding: 0.8rem 0;
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 17px;
        line-height: 30px;
        letter-spacing: 0.02em;
        color: #000000;
        padding: 0 20px;
    }
}