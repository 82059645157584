@import url('https://fonts.googleapis.com/css2?family=Alef:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alef:wght@400;700&family=Poppins:ital,wght@0,300;0,400;0,800;1,300&display=swap');

.container {
  padding: 0 2rem;
}

.telDiv {
  width: 98%;
  display: flex;
  justify-content: end;
  position: relative;
}

.telDiv div {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  background: #032D23;
  z-index: 999999;
  border-radius: 50%;
  padding: 0.8rem 1.3rem 1rem 1.5rem;
}

.active {
  width: 8px;
  height: 8px;
   /*background: none !important;*/
  border-radius: 50%;
  margin: 0 auto;
}

.fade  {
  width: 100% !important;
  margin-bottom: 10rem;
  padding-top: 1rem;
  padding-bottom: 5rem;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: 0 0;
   border: none !important;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav .nav-tabs .nav-fill {
  width: 40% !important;
}

input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
  border-top: 1px solid black !important;
  padding-bottom: 1rem !important;
}

.active:hover {
   color: black !important;
}

.nav-tabs {
   border-bottom: none !important;
}

.telDiv div a {
  text-decoration: none;
  font-size: 22px;
  color: #FFFFFF;
}

.main {
  min-height: 100vh;
  padding: 4rem 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer {
  display: flex;
  flex: 1;
  padding: 2rem 0;
  border-top: 1px solid #eaeaea;
  justify-content: center;
  align-items: center;
}

.footer a {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.title a {
  color: #0070f3;
  text-decoration: none;
}

.title a:hover,
.title a:focus,
.title a:active {
  text-decoration: underline;
}

.title {
  margin: 0;
  line-height: 1.15;
  font-size: 4rem;
}

.title,
.description {
  text-align: center;
}

.description {
  margin: 4rem 0;
  line-height: 1.5;
  font-size: 1.5rem;
}

.code {
  background: #fafafa;
  border-radius: 5px;
  padding: 0.75rem;
  font-size: 1.1rem;
  font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
    Bitstream Vera Sans Mono, Courier New, monospace;
}

.grid {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 800px;
}

.card {
  margin: 1rem;
  padding: 1.5rem;
  text-align: left;
  color: inherit;
  text-decoration: none;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  transition: color 0.15s ease, border-color 0.15s ease;
  max-width: 300px;
}
.headerNav .active {
  background-color: orange !important;
}
.card:hover,
.card:focus,
.card:active {
  color: #0070f3;
  border-color: #0070f3;
}

.card h2 {
  margin: 0 0 1rem 0;
  font-size: 1.5rem;
}

.card p {
  margin: 0;
  font-size: 1.25rem;
  line-height: 1.5;
}

.logo {
  height: 1em;
  margin-left: 0.5rem;
}

.headerNav {
  align-items: center !important;
}

@media(max-width: 980px) {
  .headerNav .active {
    display: none !important;
  }
}


@media only screen and (max-width:750px) {

  .accordion-body {
      padding: 0.5rem 1.25rem !important;
  }
  .mediaFotterLinks {
      padding: 0.5rem 1.25rem !important;
  }
}


@media (max-width: 600px) {
  .grid {
    width: 100%;
    flex-direction: column;
  }

  

}


/*heart*/

.basket_heart {
  filter: invert(1) !important;
}



/* success payment start */


.payment
{
  border:1px solid #f2f2f2;
  height:280px;
  border-radius:20px;
  background:#fff;
}
.payment_header
{
  background:green;
  padding:20px;
  border-radius:20px 20px 0px 0px;
}

.payment_header_fail {
  background:#9e1616;
  padding:20px;
  border-radius:20px 20px 0px 0px;
}

.check
{
  margin:0px auto;
  width:50px;
  height:50px;
  border-radius:100%;
  background:#fff;
  text-align:center;
}

.check i
{
  vertical-align:middle;
  line-height:50px;
  font-size:30px;
}

.content
{
  text-align:center;
  padding: 2rem;
}
.content p {
  padding: 5px 0px 15px 0px;
}
.content  h1
{
  font-size:25px;
  padding-top:25px;
}

.fail {
  background:#9e1616 !important;
}
.content a
{
  width:200px;
  height:35px;
  color:#fff;
  border-radius:30px;
  padding:5px 10px;
  text-decoration: none;
  background:green;
  transition:all ease-in-out 0.3s;
}

.content a:hover
{
  text-decoration:none;
  background:#000;
  color: #fff !important;
}

.payment_header_fail i {

}


/* success payment end */


.form-control {
  line-height: 0.5 !important;
}

