.mainTouch {
    margin: 3rem 0;
}

.err {
    color: red;
    padding-left: 0.5rem;
    font-size: 14px;
    font-weight: 400;
}

.mainTouch h1 {
    font-family: Bhavuka;
    font-style: normal;
    font-weight: normal;
    font-size: 50px;
    line-height: 63px;
    text-align: center;
    letter-spacing: 0.2em;
    color: #FFFFFF;
    border-bottom: 1px solid #EEB960;
}

.inpName {
    background: #B6BB9B !important;
    border-radius: 10px !important;
    border: none !important;
    box-shadow: none !important;
    padding: 10px 0;
    text-indent: 20px;
    margin-bottom: 0.5rem;
}

.inpName:focus {
    background: #B6BB9B !important;
    border-radius: 10px !important;
    border: none !important;
    box-shadow: none !important;
    padding: 10px 0;
    text-indent: 20px;
}

.inpName::placeholder {
    font-family: Alef;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 38px;
    letter-spacing: 0.1em;
    color: rgba(51, 43, 37, 0.5);
}

.inpTxterea {
    background: #B6BB9B !important;
    border-radius: 10px !important;
    border: none !important;
    box-shadow: none !important;
    padding: 10px 0 110px 0;
    text-indent: 20px;
}

.inpTxterea::placeholder {
    font-family: Alef;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 38px;
    letter-spacing: 0.1em;
    color: rgba(51, 43, 37, 0.5);
}

.btnSend {
    background: #B6BB9B;
    border-radius: 10px;
    width: 100%;
    border: none;
    font-family: Alef;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 35px;
    letter-spacing: 0.1em;
    color: #343626;
    transition: 0.8s;
}

.btnSend:hover {
    transition: 0.8s;
    background: #5a543a;
    border-radius: 10px;
    width: 100%;
    border: none;
    font-family: Alef;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 35px;
    letter-spacing: 0.1em;
    color: #343626;;
}


.btnSend:focus {
    background: #B6BB9B;
    border-radius: 10px;
    width: 100%;
    border: none;
    font-family: Alef;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 35px;
    letter-spacing: 0.1em;
    color: #343626;
}

.icons {
    display: flex;
    justify-content: start;
    align-items: center;
}

.icons h3 {
    font-family: Alef;
    font-style: normal;
    font-weight: normal;
    font-size: 26px;
    line-height: 38px;
    text-align: center;
    letter-spacing: 0.1em;
    color: #FFFFFF;
    border-bottom: 1px solid #EEB960;
    margin-right: 20px;
}

.iconFb {
    color: black;
    font-size: 30px;
    margin-right: 1rem;
    background: #EEB960;
    padding-left: 8px;
    border-radius: 5px;
    cursor: pointer;
}

.iconInsta {
    color: #EEB960;
    font-size: 30px;
    cursor: pointer;
}

.touchImg {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-left: 2rem;
}

@media (max-width: 550px) {

    .mainTouch h1 {
        font-family: Bhavuka;
        font-style: normal;
        font-weight: normal;
        font-size: 25px;
        line-height: 27px;
        text-align: center;
        letter-spacing: 0.2em;
        color: #FFFFFF;
        border-bottom: 1px solid #EEB960;
    }

    .btnSend {
        background: #B6BB9B;
        border-radius: 10px;
        width: 100%;
        border: none;
        font-family: Alef;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 35px;
        letter-spacing: 0.1em;
        color: #343626;
        transition: 0.8s;
    }

    .icons {
        display: flex;
        /* justify-content: space-between; */
        align-items: center;
    }

    .icons h3 {
     font-size: 12px;
        line-height: 22px;
        margin: 0;
    }

    .icons svg{
        width: 23px;
        height: 23px;
        margin-left: 10px;
    }

    .touchImg {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        margin: 0;
        padding-bottom: 2rem;
    }

    .touchImg img {
        width: 200px !important;
        height: 200px !important;
    }
}