.footherBorder {
    width: 90%;
    margin: 3rem auto;
    border: 1px solid #C4C4C4;
    border-radius: 50px;
}

.cardImages {
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.footMedia {
    display: none;  
}

.cardImages span {
    width: 70px !important;
    height: 70px !important;
}

.cardImages span:hover {
    cursor: pointer;
    opacity: 0.5 !important;
    width: 70px !important;
    height: 70px !important;
}

.footText h3 {
    font-family: Alef;
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    line-height: 38px;
    letter-spacing: 0.1em;
    color: #000000;
}

.footLink {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    text-align: start;
}

.footLink h3 {
    font-family: Alef;
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    line-height: 38px;
    letter-spacing: 0.1em;
    color: #000000;
}

.footLink a {
    font-family: Alef;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 38px;
    letter-spacing: 0.1em;
    color: #000000;
    transition: all .2s ease-in-out;
    text-decoration: none;
    text-align: start;
}
.footLink a:hover {
    font-family: Alef;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 38px;
    text-align: center;
    transform: scale(1.1);
    transition: all .2s ease-in-out;
    letter-spacing: 0.1em;
    color: #000000;
    text-decoration: none;
}

.mediaFotterLinks  a{
    text-decoration: none !important;
    color: #2f3640;
}





.footText p {
    font-family: Alef;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    transition: all .2s ease-in-out;
    letter-spacing: 0.1em;
    color: #000000;
    margin: 0.5rem 0;
}

.footText p:hover {
    cursor: pointer;
    font-family: Alef;
    font-style: normal;
    font-weight: bold;
    transform: scale(1.1);
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.1em;
    color: #000000;
    margin: 0.5rem 0;
}

.footDownText {
    text-align: center;
    margin-top: 3rem;
    width: 100%;
    background: #e0d8d8;
}

.footDownText a {
    text-decoration: none;
    color: black;
    padding: 0 10px;
}

.footDownText a:hover {
    font-weight: bold;
    text-decoration: none;
    color: black;
    padding: 0 10px;
}

.footDownText p {
    margin: 0 !important;
    padding: 0.8rem 0;
}

@media screen and (max-width: 912px) {
    .footLink {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
    }

    .footDownText {
        text-align: center;
        margin-top: 2rem;
        width: 100%;
        background: #e0d8d8;
    }

    .footMedia {
        display: block;
    }
    .footLink {
        display: none;
    }

    .footText2 {
        display: none !important;
    }

    .footText2 {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin: 0 auto;
    }

    .cardImages {
        width: 100%;
        display: grid;
        grid-template-columns: auto auto;
    }

    .cardImages img {
        width: 100px;
        height: 100px;
    }
}


@media only screen and (max-width:768px) {
    .footherBorder {
        margin: 1rem auto;

    }
}
