.FloralText {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 2rem 0;
}
.productClickSpan img{
    width: 50px;
}
.FloralText div {
    border: 2px solid #EEB960;
    width: 30%;
}

.FloralText h1 {
    font-family: Bhavuka;
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    line-height: 63px;
    text-align: center;
    letter-spacing: 0.2em;
    color: #332B25;
}

.floralTextP {
    text-align: center;
}

.floralTextP p {
    font-family: Alef;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 38px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #46362B;
}

.mainProduct {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 100% !important;
    margin-bottom: 3rem;
}

.productDiv:hover {
    opacity: 0.9;
    transition: opacity 1s;
}

.productDiv {
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: end;
    border: 20px solid #B6BB9B;
    box-sizing: border-box;
    border-radius: 35px;
    position: relative;
    cursor: pointer;
}

.productDiv img {
    object-fit: cover;
    border-radius: 15px;
}

.productDiv img:hover {
    transform: scale(1.05);
    transition: 0.3s;
    border-radius: 15px;
    /* opacity: 0.8 !important; */
}



.productPrice {
    position: absolute;
    top: -14px;
    right: -15px;
    background: linear-gradient(106.94deg, #DCB0A5 -18.38%, #B6BB9B 103.01%);
    border: 6px solid #FFF8F8;
    border-radius: 49px;
    width: auto;
    height: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.www {
    position: absolute;
    top: 40%;
    width: 100%;
    height: 58px;
    display: none;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
    color: #FFFFFF;
    opacity: 0.5;
}

.www h2 {
    font-size: 13px;
}

.productPrice p {
    margin: 0;
    font-family: Fjalla One;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 30px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    padding: 0 10px;
}

.productText {
    display: flex;
    justify-content: start;
    flex-direction: column;
    align-items: start;
    margin-right: 80px;
    margin-top: 0.5rem;
    width: 40%;
    max-width: 40%;
}

.productText h2 {
    font-family: Abel;
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 46px;
    letter-spacing: 0.05em;
    color: #000000;
    display: flex !important;
    justify-content: start !important;
    flex-wrap: wrap !important;
    align-items: start !important;
    text-align: start;
}

.productText h3 {
    font-family: Abel;
    font-style: normal;
    font-weight: normal;
    font-size: 29px;
    line-height: 30px;
    /*text-align: center;*/
    letter-spacing: 0.05em;
    color: #000000;
}

.productClick {
    display: flex !important;
    width: 55%;
    margin-top: 0.5rem;
}

.productClick span {
    margin-right: 6px !important;
    cursor: pointer;
}

.productClickSpan {
    width: 38%;
    display: flex !important;
    justify-content: space-between !important;
}

.productClick span img {
    background: #BD9085;
    border: 5px solid #FFFFFF;
    padding: 10px !important;
    border-radius: 50%;
    width: 45px !important;
}

.productClick span img:hover {
    background: rgba(220, 176, 165, 0.6);
    border: 5px solid #FFFFFF;
    padding: 10px !important;
    border-radius: 50%;
    width: 45px !important;
}

.productSee {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 42px;
    color: #FF621F;
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 2rem;
    cursor: pointer;
}

.setImg {
    /*background: linear-gradient(310.04deg, #a5766d -15.06%, #803a27 113.36%) !important;important*/
    border: 2px solid red !important;
    padding: 10px !important;
    border-radius: 50% !important;
    width: 45px !important;
}
.basket_svg{
    background: #edcccc;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
}

.basket_svg:hover{
    background: #b86c6c !important;
    transition: .4s;
}

.activeBasket{
    background: linear-gradient(310.04deg, #a50f1f -15.06%, #800036 113.36%) !important;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
}

.activeBasket:hover{
    background: linear-gradient(310.04deg, #a5501a -15.06%, #805b64 113.36%) !important;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
}

@media screen and (max-width: 1400px) {
    .productClick {
        display: flex !important;
        width: 65%;
        max-width: 100% !important;
        margin-top: 0.5rem;
        justify-content: start;
    }

    .productText {
        display: flex;
        justify-content: start;
        flex-direction: column;
        align-items: start;
        margin-right: 50px;
        margin-top: 0.5rem;
        max-width: 100%;
    }
}

@media screen and (max-width: 995px) {
    .productPrice  p{
        font-size: 19px;
    }
    .mainProduct {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: 3rem;
    }

    .www h2 {
        font-size: 13px;
    }

    .productDiv {
        flex-direction: column;
        border: 20px solid #B6BB9B;
        box-sizing: border-box;
        border-radius: 35px;
        position: relative;
        cursor: pointer;
    }

    .productDiv img {
        object-fit: cover;
        width: 100%;
    }

    .productText {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-right: 0;
        text-align: center;
        margin-top: 0.5rem;
        max-width: 100%;
    }

    .productClick {
        display: flex !important;
        width: 100%;
        max-width: 100% !important;
        margin-top: 0.5rem;
        justify-content: center;
    }

    .productClickSpan {
        width: 80%;
        display: flex !important;
        justify-content: center !important;
    }

    .productText h2{
        font-size: 20px;
    }
}

@media screen and (max-width: 770px) {
    .mainProduct {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100% !important;
        margin-bottom: 3rem;
    }


    .productDiv {
        flex-direction: column;
        border: 20px solid #B6BB9B;
        box-sizing: border-box;
        border-radius: 35px;
        position: relative;
        cursor: pointer;
        width: 100%;
    }

    .productDiv img {
        object-fit: cover;
        width: 100%;
    }

    .productText {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-right: 0;
        text-align: center;
        margin-top: 0.5rem;
        max-width: 100%;
    }

    .productClick {
        display: flex !important;
        width: 100%;
        max-width: 100% !important;
        margin-top: 0.5rem;
        justify-content: center;
    }

    .productClickSpan {
        width: 80%;
        display: flex !important;
        justify-content: center !important;
    }
}