.aboutUsBg {
    background-color: #cccccc; /* Used if the image is unavailable */
    height: 25rem; /* You must set a specified height */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover;
    display: flex;
    justify-content: center;
}

.aboutUsBg h1 {
    font-family: Bhavuka;
    font-style: normal;
    font-weight: normal;
    font-size: 70px;
    line-height: 202px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #EEB960;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.aboutH1 h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 38px;
    line-height: 57px;
    color: #000000;
    /*padding-left: 1rem;*/
}

.aboutP p {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 44px;
    /*text-align: center;*/
    letter-spacing: 0.07em;
    color: #000000;
}

.aboutBg2 {
    background-image: url("../../Utils/images/bgFooter.png"); /* The image used */
    background-color: #cccccc; /* Used if the image is unavailable */
    height: 22rem; /* You must set a specified height */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover;
}

@media only screen and (max-width: 915px) {

    .aboutH1 h2 {
        text-align: center;
        font-size: 25px;
    }

    .aboutH1 p {
        text-align: center;
        font-size: 20px;
    }

    .aboutUsBg h1 {
        font-family: Bhavuka;
        font-style: normal;
        font-weight: normal;
        font-size: 60px;
        line-height: 100px;
        text-align: center;
        letter-spacing: 0.05em;
        color: #EEB960;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding: 60px;
    }
    .aboutBg2 {
        background-image: url("../../Utils/images/bgFooter.png"); /* The image used */
        background-color: #cccccc; /* Used if the image is unavailable */
        height: 12rem; /* You must set a specified height */
        background-position: center; /* Center the image */
        background-repeat: no-repeat; /* Do not repeat the image */
        background-size: cover;
        /* margin-top: 5rem; */
    }
}

@media only screen and (max-width: 400px) {

    .aboutH1 h2 {
        text-align: center;
        font-size: 25px;
    }

    .aboutH1 p {
        text-align: center;
        font-size: 20px;
    }
    .aboutUsBg h1 {
        font-family: Bhavuka;
        font-style: normal;
        font-weight: normal;
        font-size: 30px;
        line-height: 40px;
        text-align: center;
        letter-spacing: 0.05em;
        color: #EEB960;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top:40px ;
    }
    .aboutBg2 {
        background-image: url("../../Utils/images/bgFooter.png"); /* The image used */
        background-color: #cccccc; /* Used if the image is unavailable */
        height: 5rem; /* You must set a specified height */
        background-position: center; /* Center the image */
        background-repeat: no-repeat; /* Do not repeat the image */
        background-size: cover;
        /* margin-top: 5rem; */
    }
}


@media only screen and (min-width:2163px) {
    .aboutBg2 {
        background-image: url("../../Utils/images/bgFooter.png"); /* The image used */
        background-color: #cccccc; /* Used if the image is unavailable */
        height: 30rem !important; /* You must set a specified height */
        background-position: center; /* Center the image */
        background-repeat: no-repeat; /* Do not repeat the image */
        background-size: cover;
    }
}

@media only screen and (max-width:1215px) {
    .aboutBg2 {
        height: 16rem !important; /* You must set a specified height */
    }
}

@media only screen and (max-width:900px) {
    .aboutBg2 {
        height: 11rem !important; /* You must set a specified height */
    }
}

@media only screen and (max-width:600px) {
    .aboutBg2 {
        height: 8rem !important; /* You must set a specified height */
    }
}

@media only screen and (max-width:480px) {
    .aboutBg2 {
        height: 6rem !important; /* You must set a specified height */
    }
}

@media only screen and (max-width:400px) {
    .aboutBg2 {
        height: 4rem !important; /* You must set a specified height */
    }
}


@media only screen and (max-width:426px) {
.aboutP p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 44px;
    text-align: center;
    letter-spacing: 0;
    color: #000000;
}

}